import React from "react"
import { graphql } from "gatsby"
// import { Link } from "gatsby";

import Head from "../components/head"
import Layout from "../components/layout"
import Section from "../components/section"
import Grid from "../components/grid"
import Card from "../components/card-text-link"

const PageTitle = "Compétences et Techologies"

const randomSort = arr => arr.sort(() => Math.random() - 0.5)

const filterServices = ({ data }) => {
  var servicesArray = []
  data.allMarkdownRemark.edges.forEach(({ node }) => {
    servicesArray = servicesArray.concat(node.frontmatter.services)
    servicesArray = Array.from(new Set(servicesArray))
    // servicesArray = servicesArray.sort();
  })
  return servicesArray
}

const printServices = ({ data }) => {
  var servicesArray = filterServices({ data })
  return servicesArray.map((service, index) => {
    return (
      <li key={index} data-uk-filter-control={"[data-services*='" + service + "']"}>
        <a href="/#">{service}</a>
      </li>
    )
  })
}

export default ({ data }) => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/skills/" />

    <Layout>
      <Section Style="secondary uk-light uk-text-center">
        <h3>{PageTitle}</h3>
        <div>Voici les compétences, outils et technologies que j'utilise pour mes projets.</div>
      </Section>

      <Section Style="muted">
        <div data-uk-filter="#js-filter">
          <ul className="uk-subnav uk-subnav-pill uk-flex uk-flex-center">
            <li data-uk-filter-control="" className="uk-active">
              <a href="/#">tous</a>
            </li>

            {printServices({ data })}
          </ul>

          <Grid Id="js-filter" Col="4">
            {/* {data.allMarkdownRemark.edges.map(({ node }) => { <========================================= TEMP */}
            {randomSort(data.allMarkdownRemark.edges).map(({ node }) => {
              const nodeServices = node.frontmatter.services.join(" ")
              return (
                <div key={node.id} data-services={nodeServices}>
                  <Card
                    ImageFluid={node.frontmatter.cover_image.childImageSharp.fluid}
                    Level={node.frontmatter.level}
                    Title={node.frontmatter.title}
                    Subtitle=""
                    Intro={node.excerpt}
                    LinkUrl={node.fields.slug}
                    LinkText="Découvrir"
                  />
                </div>
              )
            })}
          </Grid>
        </div>
      </Section>
    </Layout>
  </div>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }, filter: { frontmatter: { categoryId: { eq: "skills" } } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY")
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 225) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            services
            level
          }
          fields {
            slug
          }
          excerpt(pruneLength: 90)
        }
      }
    }
  }
`
